<template>
  <div>
    <Row>
      <i-col span="24" class="text-right">
        <i-button type="primary" class="m-r-5" size="small" @click="showProductPublicityModal">项目结果公示</i-button>
      </i-col>
    </Row>
    <div style="font-size: 14px; ">
      基础信息
    </div>
    <Row class="p-b-5 p-t-10" style="line-height:24px;">
      <i-col span="24"
        ><span class="title">项目名称 </span>{{ investmentInfo.name }}</i-col
      >
      <i-col span="8"
        ><span class="title">业态规划 </span>{{ investmentInfo.industryName }}</i-col
      >
      <i-col span="8"
        ><span class="title">招租方式 </span
        >{{ investmentInfo.rentTypeName }}</i-col
      >
      <i-col span="8"
        ><span class="title">起租时间 </span>{{ investmentInfo.tenancy }}个月</i-col
      >
      <i-col span="8"
        ><span class="title">起始单价 </span
        >{{ investmentInfo.rentAmount}}元/㎡·月</i-col
      >
      <i-col span="8"
        ><span class="title">免租时间 </span
        >{{ investmentInfo.freePeriod  }}个月</i-col
      >
      <i-col span="8"
        ><span class="title">保证金 </span
        >{{formatMoney(investmentInfo.depositAmount)  }}</i-col
      >
      <i-col span="8"
        ><span class="title">违约金 </span>{{ formatMoney(investmentInfo.penaltyAmount) }}</i-col
      >

      <i-col span="8"
        ><span class="title">滞纳金 </span>{{ formatMoney(investmentInfo.delayAmount)}}</i-col
      >

      <i-col span="8"
        ><span class="title">施工押金 </span>{{ formatMoney(investmentInfo.constructionAmount) }}</i-col
      >

      <i-col span="8"
        ><span class="title">物业费 </span>{{ investmentInfo.propertyAmount }}元/㎡·月</i-col
      >

      <i-col span="8"
        ><span class="title">能耗费 </span>{{ investmentInfo.energyAmount }}元/月</i-col
      >

      <i-col span="8"
        ><span class="title">合同模板 </span>{{ investmentInfo.templateName }}</i-col
      >

      <i-col span="8"
        ><span class="title">租赁开始时间 </span>{{ investmentInfo.rentStartDate }}</i-col
      >
      <i-col span="24"
        ><span class="title">招商资产 </span>

        <a @click="showDeviceList()"> 查看明细</a>

        </i-col
      >
    </Row>
    <Tabs value="customer" class="text-white">
        <TabPane label="报名客户" name="customer">
            <Table stripe  :columns="columnData"  :data="tableData"></Table>
        </TabPane>
    </Tabs>
    <Modal v-model="deviceListModal" width="1200" footer-hide>
      <p slot="header" class="text-center">招商资产列表</p>
      <deviceList v-if="deviceListModal" :deviceArray="investmentDeviceArray" ></deviceList>
      <div slot="footer">
        <Button type="text" class="m-r-5" @click="deviceListModal = false">关闭</Button>
        <!-- <Button type="primary" @click="handleCreateInvestemnt">确定</Button> -->
      </div>
    </Modal>
    <publicityModal ref="productPublic"></publicityModal>
    <!-- <Row>
      <i-col span="24" class="m-b-5">
        <a
          style="float: right; line-height: 30px"
          @click="handleChoiceDevices()"
          >+添加资产</a
        >
      </i-col>
    </Row> -->
    <!-- <Table
      size="small"
      :columns="columnManagedata"
      :data="tableData"
      stripe
    ></Table> -->
  </div>
</template>

<script>
// import { getPositions } from '@/api/product/resource'
// import { addDevice, updateDevice } from '@/api/product/deviceedit'
// import { ParseDate } from '@/utils/dateFormat'
import deviceList from './DeviceList.vue'
import publicityModal from './publicity.vue'
import { toMoney } from '@/utils/wnumb_own'
import { downloadFileRequest } from '@/utils/download'
/** new api */
import { getInvestmentProject, listDevice, listSinInBusiness, orderRefund } from '@/api/contract/invest'
export default {
  components: {
    deviceList,
    publicityModal
  },
  props: {},
  data () {
    return {
      investmentInfo: {
      },
      deviceListModal: false,

      tableData: [],
      columnData: [
        {
          title: '用户名',
          key: 'userName'
        },
        { title: '联系电话', key: 'userPhone' },
        { title: '报名时间', key: 'createTime' },
        {
          title: '上传材料',
          key: 'freePeriod',
          render: (h, params) => {
            const result = []
            params.row.uploadMaterialList.forEach(item => {
              result.push(
                h('p', [h(
                  'a',
                  {
                    style: {
                      marginRight: '5px'
                    },
                    on: {
                      click: () => {
                        downloadFileRequest(item.value, {}, item.name)
                      }
                    }
                  },
                  item.name
                )])
              )
            })
            return h('div', result)
          }
        },
        { title: '保证金状态', key: 'orderStatusName' },
        { title: '状态', key: 'publicStatusName' },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            const refundButton = h(
              'a',
              {
                style: {
                  color: 'red',
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    let remark = ''
                    this.$Modal.confirm({
                      title: '客户退款',
                      render: h => {
                        return h('Input', {
                          props: {
                            value: remark,
                            placeholder: '请输入退款备注'
                          },
                          on: {
                            'on-change': event => {
                              remark = event.target.value
                            }
                          }

                        })
                      },
                      onOk: () => {
                        orderRefund({ investId: this.investmentId, orderNo: params.row.orderNo, remark: remark }).then(res => {
                          if (res && !res.errcode) {
                            this.$Notice.success({ desc: '退款成功' })
                            // window.location.reload()
                            this.initSinInBusiness()
                          }
                        })
                      }
                    })
                  }
                }
              },
              '退款'
            )

            return h('div', params.row.orderStatus === 1 ? [refundButton] : [])
          }
        }
      ]
    }
  },
  mounted () {},
  created () {
    if (this.investmentId) {
      this.initInvestmentDetail()
      this.initInvestmentDevices()
    }
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },
    initInvestmentDetail () {
      const that = this
      getInvestmentProject({ investId: that.investmentId }).then(res => {
        that.investmentInfo = res
        that.initSinInBusiness()
      })
    },
    showDeviceList () {
      this.deviceListModal = true
    },
    initInvestmentDevices () {
      const that = this
      listDevice({ investId: that.investmentId }).then(res => {
        that.investmentDeviceArray = res
      })
    },
    showProductPublicityModal () {
      this.$refs.productPublic.showModal()
    },

    initSinInBusiness () {
      const that = this
      listSinInBusiness({ investId: that.investmentId }).then(res => {
        that.tableData = res
      })
    }
  },
  watch: {
    investmentId () {
      this.initInvestmentDetail()
    },
    beginUpdate () {
      this.initInvestmentDetail()
    }
  },
  computed: {
    investmentId () {
      return this.$store.state.investmentManage.investmentId
    },
    beginUpdate () {
      return this.$store.state.investmentManage.beginUpdate
    }
  }
}
</script>
